<template>
    <div>
        <div class="firm-panel firm_detail_panel">
            <h3 class="g-title">团队成员详情</h3>

            <div class="team-detail-group" v-if="personal">
                <span class="avatar">
                    <img :src="util.reImg(personal.avatar)" />
                </span>
                <h4 class="name">
                    {{personal.name}}
                    <i class="jk-icon-male" v-if="personal.sex==1"></i>
                    <i class="jk-icon-female" v-else></i>
                </h4>
                <div class="detail-cont">
                    <h5>基础信息</h5>
                    <ul class="datail-msg">
                        <li>
                            年龄：
                            <span>{{personal.age}}岁</span>
                        </li>
                        <li>
                            学历：
                            <span>{{personal.education}}</span>
                        </li>
                        <li>
                            出生年月：
                            <span>{{personal.birthday}}</span>
                        </li>
                        <li>
                            职称：
                            <span>{{personal.professional_qualifications}}</span>
                        </li>
                        <li>
                            专业：
                            <span>{{personal.profession}}</span>
                        </li>
                        <li>
                            参加工作时间：
                            <span>{{personal.experience_date}}</span>
                        </li>
                    </ul>
                </div>
                <div class="detail-cont">
                    <h5>资格证书</h5>
                    <section class="team-honor">
                        <article
                            class="item"
                            v-for="(item,index) of personal.certificate"
                            :key="index"
                        >
                            <span class="icon">
                                <img :src="websiteConfigs.sourceUrl + item.image" />
                            </span>
                            <div class="team-txt">
                                <h4 class="honor-name">{{item.title}}</h4>
                                <p class="number">{{item.code}}</p>
                            </div>
                        </article>
                    </section>
                </div>
            </div>

            <p
                v-else
                style="width:100%;text-align:center;height:300px;line-height:300px;border:1px solid #e6e6e6;font-size:14px;color:#999;"
            >暂无数据</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "team",
    computed: {
        personal: function () {
            let data = sessionStorage.getItem('team_detail_data');
            if (data) {
                return JSON.parse(data);
            }
            return '';
        }
    }
};
</script>

<style lang="less">
.team-detail-group .team-honor .item {
    padding: 15px;
    .honor-name {
        font-size: 14px;
    }
}
.firm_detail_panel {
    .team-honor .item {
        width: 300px !important;
    }
    .team-honor .team-txt {
        width: 224px;
        .honor-name {
            font-size: 12px !important;
        }
    }
    .team-honor {
        width: 104% !important;
    }
}
</style>





